import React, { Component, Suspense, useState, useEffect } from "react";
import { loadPurchaseOrderInfo } from "../../services/orderService";
import { setCurrency } from "../../helpers/functions.js";

import NoteComponent from "../../components/NoteComponent";
// import { exportToPdf } from "../../../helper/functions/toPdf";

const InitOrder = {
  orderCode: '',
  createdDate: null,
  user: {
    name: '',
    phoneNumber: '',
  },
  shipmentAddressDetail: [],
  shippingOrder: [],
}

const styles = {
  font: {
    fontSize: 12,
  },
}

class PurchaseOrder extends Component {

  constructor(props) {
    super(props);
    this.state = {
      printed: false,
      imei: '',
      loading: false,
      order: {
        ...InitOrder,
      },
      productOrders: [],
      paymentOrders: [],
      datetimeaccess: null,
      region: '',
      error: ''
    }
    document.body.setAttribute('style', 'background-color:white!important')
  }

  componentDidMount = async () => {
    try {
      // fix for a while
      let download = window.location.pathname.split('/')[3];
      await this.getInvoice()
      if (this.state.printed === false && download === 'download') {
        if (!this.state.loading) {
          this.setState({
            printed: true
          }, () => {
            import("../../helpers/toPdf").then(c => {
              c.exportToPdf(document.querySelector("#capture"), `PO${this.state.order.orderCode}${new Date().getTime()}`);
            })
          });
        }
      }
    } catch (error) {
      this.setState({ error: error.message || error });
    }
  }  

  getInvoice = async () => {
    try {
      this.setState({ loading: true });
      const id = this.props.match.params.id;
      const { data, error } = await loadPurchaseOrderInfo(id);
      if (error) throw error;
      if (data) {
        console.log(data);
        this.setState({
          order: {
            ...InitOrder,
            ...data,
          },
          shippingOrder: data.shippingOrder,
          paymentOrders: data.paymentOrders,
          datetimeaccess: data.datetimeaccess,
          loading: false
        });
      }
    } catch (error) {
      this.setState({ error: error.message || error });
    }
  }

  render() {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <NoteComponent
          loading={this.state.loading}
          title="PURCHASE ORDER"
          datetimeaccess={this.state.datetimeaccess}
          order={this.state.order}
          shippingOrders={this.state.shippingOrder}
        >
          {/* <Table className="text-center">
            <thead>
              <tr style={styles.font}>
                <th>Payment Method</th>
                <th>Amount</th>
                <th>Due Date</th>
              </tr>
            </thead>

            <tbody>
              {!this.state.loading && this.getPaymentList(this.state.paymentOrders)}
            </tbody>
          </Table> */}

        </NoteComponent>
      </Suspense>
    )
  }
}

// function PurchaseOrder ({
//   ...props
// }) {

//   const [order, setOrder] = useState(InitOrder);
//   const [loading, setLoading] = useState(false);
//   const [docInfo, setDocInfo] = useState({
//     datetimeaccess: null,
//     printed: false
//   })

//   useEffect(() => {
//     const loadInvoice = async () => {
//       await getInvoice()
//     }
//     loadInvoice()
//   }, [docInfo, order])

//   const getInvoice = async () => {
//     try {
//       setLoading(true)
//       const id = props.match.params.id;
//       const { data, error } = await loadPurchaseOrderInfo(id);
//       if (error) throw error;
//       if (data) {
//         let currData = data;
//         setOrder({
//           ...InitOrder,
//           ...currData
//         })
//         // setDocInfo({
//         //   ...docInfo,
//         //   datetimeaccess: currData
//         // })
//         setLoading(false)
//       }
//     } catch (error) {
//       console.log(error)
//     }
//   }

//   return (
//     <div>Hello World</div>
//     // <Suspense fallback={<div>Loading...</div>}>
//     //   <NoteComponent
//     //     loading={loading}
//     //     title="PURCHASE ORDER"
//     //     datetimeaccess={docInfo.datetimeaccess}
//     //     order={order}
//     //   >
//     //     {/* <Table className="text-center">
//     //       <thead>
//     //         <tr style={styles.font}>
//     //           <th>Payment Method</th>
//     //           <th>Amount</th>
//     //           <th>Due Date</th>
//     //         </tr>
//     //       </thead>

//     //       <tbody>
//     //         {!this.state.loading && this.getPaymentList(this.state.paymentOrders)}
//     //       </tbody>
//     //     </Table> */}

//     //   </NoteComponent>
//     // </Suspense>
//   );
// }

export default PurchaseOrder;