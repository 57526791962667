import _set from 'lodash/set';
import moment from 'moment';

export const setCurrency = (value) => {
  try {
    return `IDR ${numberFormatter(value)}`;
  } catch (error) {
    console.error(error);
  }
}

export const setDateFormat = (date, format = 'YYYY/MM/DD H:mm:ss') => {
    try {
      if (date) {
        return moment(date).format(format);
      }
      return "-";
    } catch (error) {
      console.error(error);
      return '-';
    }
}

export const numberFormatter = (n, options) => {
  if (typeof n !== 'number') {
    if (typeof Number(n) !== 'number') {
      return '-';
    }
    n = Number(n);
  }
  return new Intl.NumberFormat("en-US", options).format(n);
}

export const setURLImage = (productImages = '', filename = '', isOWNPOP = '') => {
    let isFullURL = isOWNPOP === '88';
    let imageUrl = '';
    let filenames = filename.replace(/[^A-Z0-9]+/ig,'')
    console.log('ini filenames',filenames)
    if (isFullURL) {
      // imageUrl = productImages;
      imageUrl = `//images.weserv.nl/?url=${productImages}&filename=${filenames}`;
    } else {
      if (productImages.includes('https')) {
        imageUrl = `//images.weserv.nl/?url=${productImages}&filename=${filenames}`;
      } else
        imageUrl = `https://id.360buyimg.com/Indonesia/${productImages}`;
    }
    return imageUrl;
}