import axios from "../helpers/Axios";
import config from "../config";
const localStorageidealAdmin = localStorage.getItem('idealAdmin');
const idealAdmin = localStorageidealAdmin ? JSON.parse(localStorageidealAdmin) : null

export async function getOrder(queryParams) {
  try {
    let data = await axios.Get({
        url: `${config.basev2URL}/transactions?${queryParams}`,
    });
    if (data.statusCode !== 200) {
      return {  error: data.message };
    }
    return { data: data.data };
  } catch (e) {
    return { error: e };
  }
}

export async function loadOrderDetail (queryParams) {
  try {
    let data = await axios.Get({
        url: `${config.basev2URL}/orders/${queryParams.orderId}`,
    });
    if (data.statusCode !== 200) {
      return {  error: data.message };
    }
    return { data: data.data };
  } catch (e) {
    return { error: e };
  }
}

export async function loadOrderSettlementDetail (queryParams) {
  try {
    let data = await axios.Get({
        url: `${config.basev2URL}/settlements/${queryParams.settlementId}`,
    });
    if (data.statusCode !== 200) {
      return {  error: data.message };
    }
    return { data: data.data };
  } catch (e) {
    return { error: e };
  }
}

export async function updateOrderStatus(queryParams) {
  try {
    let data = await axios.Get({
      url: `${config.basev2URL}/updatetransaction?${queryParams}`
    });
    if (data.statusCode !== 200) {
      return {  error: data.message };
    }
    return { data: data.data };
  } catch (e) {
    return { error: e };
  }
}

export async function uploadResiorBast({ url, orderId, noresibast, bastname, imageUrl, documentInfo }) {
  try {
    let data = await axios.Post({
      url: `${config.basev2URL}/${url}`,
      data: {orderId, noresibast, bastName: bastname, imageUrl, idealAdmin, documentInfo}
    });
    if (data.statusCode !== 200) {
      return {  error: data.message };
    }
    return { data: data.data };
  } catch (e) {
    return { error: e };
  }
}

export async function updateSettlement ({url, orderId, noresibast, bastname, imageUrl}) {
  try {
    let data = await axios.Post({
      url: `${config.basev2URL}/${url}`,
      data: {orderId, noresibast, bastName: bastname, imageUrl, idealAdmin}
    });
    if (data.statusCode !== 200) {
      return {  error: data.message };
    }
    return { data: data.data };
  } catch (e) {
    return { error: e };
  }
}

export async function processOrder ({
  orderId, settlement, shippingFee, shippingAdditionalInfo, payAmount, sellerInfo, bankadminFee, sellerChatImage
}) {
  try {
    let data = await axios.Post({
      url: `${config.basev2URL}/partner/process`,
      data: { orderId, settlement, shippingFee, shippingAdditionalInfo, payAmount, sellerInfo, bankadminFee, sellerChatImage, idealAdmin }
    });
    if (data.statusCode !== 200) {
      return {  error: data.message };
    }
    return { data: data.data };
  } catch (e) {
    return { error: e };
  }
}

export async function passToOrder ({
  orderId, passToMerchant, settlement, shippingFee, payAmount
}) {
  try {
    let data = await axios.Post({
      url: `${config.basev2URL}/partner/passto`,
      data: { orderId, passToMerchant, settlement, shippingFee, payAmount, idealAdmin }
    });
    if (data.statusCode !== 200) {
      return {  error: data.message };
    }
    return { data: data.data };
  } catch (e) {
    return { error: e };
  }
}

export async function rejectOrder ({orderId, rejectReason}) {
  try {
    let data = await axios.Post({
      url: `${config.basev2URL}/partner/reject`,
      data: { orderId, rejectReason, idealAdmin }
    });
    if (data.statusCode !== 200) {
      return {  error: data.message };
    }
    return { data: data.data };
  } catch (e) {
    return { error: e };
  }
}

export async function updateOrder ({
  orderId, settlement, shippingFee, shippingAdditionalInfo, sellerInfo, payAmount, bankadminFee, sellerChatImage
}) {
  try {
    let data = await axios.Post({
      url: `${config.basev2URL}/partner/update`,
      data: { orderId, settlement, shippingFee, shippingAdditionalInfo, payAmount, sellerInfo, bankadminFee, sellerChatImage, idealAdmin}
    });
    if (data.statusCode !== 200) {
      return {  error: data.message };
    }
    return { data: data.data };
  } catch (e) {
    return { error: e };
  }
}

export async function getOrderfromCreateOrder (queryParams) {
  try {
    let data = await axios.Get({
      url: `${config.basev2URL}/ideal-order/order?${queryParams}`,
    });
    if (data.statusCode !== 200) {
      return {  error: data.message };
    }
    return { data: data.data };
  } catch (e) {
    return { error: e }
  }
}

export async function createMerchantOrder ({ 
  merchantId,
  body
}) {
  try {
    let data = await axios.Post({
      url: `${config.basev2URL}/ideal-order/order/create`,
      data: { merchantId, body, idealAdmin }
    });
    if (data.statusCode !== 200) {
      return {  error: data.message };
    }
    return { data: data.data };
  } catch (e) {
    return { error: e }
  }
}

export async function loadPurchaseOrderInfo (orderId) {
  try {
    let data = await axios.Get({
      url: `${config.basev2URL}/v2/purchase-order?orderId=${orderId}`,
    });
    if (data.statusCode !== 200) {
      return {  error: data.message };
    }
    return { data: data.data};
  } catch (e) {
    return { error: e }
  }
}

export async function loadSellerList ({
  name
}) {
  try {
    let data = await axios.Get({
      url: `${config.basev2URL}/v2/seller?name=${name}`,
    });
    if (data.statusCode !== 200) {
      return {  error: data.message };
    }
    return { data: data.data }
  } catch (e) {
    return { error : e }
  }
}

export async function loadSellerRecommendation ({
  jdProduct, shipmentId
}) {
  try {
    // 
    let data = await axios.Get({
      url: `${config.basev2URL}/v2/seller-recomm?product=${jdProduct}&shipmentId=${shipmentId}`,
    });
    if (data.statusCode !== 200) {
      return {  error: data.message };
    }
    return { data: data.data }
  } catch (e) {
    return { error: e }
  }
}

export async function loadLogisticList ({
  name
}) {
  try {
    let data = await axios.Get({
      url: `${config.basev2URL}/v2/logistic?name=${name}`,
    });
    if (data.statusCode !== 200) {
      return {  error: data.message };
    }
    return { data: data.data }
  } catch (e) {
    return { error : e }
  }
}