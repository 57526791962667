import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

interface Props {
  isOpen: boolean;
  isLoadingConfirm: boolean;
  buttonConfirmDisabled?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  cancelButtonText?: string;
  confirmButtonText?: string;
  children: React.ReactNode;
  title: string;
  showCancelButton?: boolean;
  showConfirmButton?: boolean;
}

const FormModal = (props: Props) => {
  let {
    title,
    buttonConfirmDisabled,
    children,
    confirmButtonText,
    cancelButtonText,
    onConfirm,
    onCancel,
    isOpen,
    isLoadingConfirm,
    showCancelButton,
    showConfirmButton,
  } = props;

  return (
    <Modal isOpen={isOpen} toggle={onCancel} centered>
      <BlockUi tag="div" blocking={isLoadingConfirm}>
        <ModalBody className="wrap-modal-btn-change">
          <h3 className="mb-4 text-center">{title}</h3>
          {children}
          {(showConfirmButton || showCancelButton) && (
            <div className="row">
              <div className="modal-button">
                {showCancelButton && (
                  <Button className="btn-white mr-4 ml-4" onClick={onCancel}>
                    {cancelButtonText}
                  </Button>
                )}
                {showConfirmButton && (
                  <Button
                    className={
                      showCancelButton
                        ? 'btn-green mr-4 ml-4'
                        : 'btn-primary full-width'
                    }
                    onClick={onConfirm}
                    disabled={buttonConfirmDisabled}
                  >
                    {confirmButtonText}
                  </Button>
                )}
              </div>
            </div>
          )}
        </ModalBody>
      </BlockUi>
    </Modal>
  );
};

export default FormModal;
