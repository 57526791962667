import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FormModal from './FormModal';
import { authSelector, authDispatcher } from 'models/auth';
import Toast from '../../helpers/toast';
import ResetPasswordComponent from '../ResetPasswordComponent';

// Validate
import errorCaster from 'helpers/errorCaster';
import FormErrorLabel from '../FormErrorLabel';

// Interface
import { ApiError } from '../../repositories/ApiError';

const ResetPasswordModal = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { getMe } = authDispatcher(dispatch);
  const user = useSelector(authSelector.userSelector);

  const onSuccess = () => {
    getMe();
    Toast.success('Success Reset Password');
  };

  const onFailed = (e: ApiError) => {
    Toast.error(e.message);
  };

  const onLoadingChange = (state: boolean) => {
    setLoading(state);
  };

  return (
    <React.Fragment>
      {user ? (
        <FormModal
          isOpen={user.firstTimeLogin}
          title="Reset Password Form"
          buttonConfirmDisabled={false}
          isLoadingConfirm={loading}
          showCancelButton={false}
          showConfirmButton={false}
        >
          <ResetPasswordComponent
            onSuccess={onSuccess}
            onFailed={onFailed}
            onLoadingChange={onLoadingChange}
            onDisableButton={false}
          />
        </FormModal>
      ) : null}
    </React.Fragment>
  );
};

export default ResetPasswordModal;
