export default {
  items: [
   {
      name: "Settings",
      url: "/settings",
      icon: "fa fa-ticket",
      bliEnable: false,
      mdEnable: false
    },
    {
      name: "Product",
      url: "/product/listproduct",
      icon: "fa fa-archive",
      bliEnable: false,
      mdEnable: true
    },
    {
      name: "Order",
      url: "/orders/listorders",
      icon: "fa fa-dollar",
      bliEnable: true,
      mdEnable: true
    }
  ]
};
