import React, { Component } from 'react'
import {
  Table
} from "reactstrap";
import { setCurrency, setDateFormat, setURLImage } from '../helpers/functions.js';
import iDeal from '../assets/img/brand/logo-ideal-horizontal.png';
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';

const styles = {
  flexCenter: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  flexBetween: {
    justifyContent: 'space-between',
  },
  borderNone: {
    border: 'none',
  },
  font: {
    fontSize: 12,
  },
}

export default class NoteComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      region: '',
      printed: false,
      imei: '',
    }
    document.body.setAttribute('style', 'background-color:white!important')
  }
  // ui
  getOrderList = (productOrders) => {
    try {
      console.log(productOrders);
      if (productOrders.length <= 0) return;
      return productOrders.map(data => {
        const productImage = data.productImage ? setURLImage(data.productImage, '', data.jdProduct ? data.jdProduct.productisOWNPOP : '') : ''
        const variantKeys = data.jdProduct.saleAttributeNameMap ? Object.keys(data.jdProduct.saleAttributeNameMap) : [];
        let productVariant = ''
        if (variantKeys) { productVariant = variantKeys.length > 0 ? ` - ${data.jdProduct.saleAttributeNameMap[variantKeys[0]]}` : ''; }
        return (
          <tr style={styles.font} key={data._id}>
            <td>
              {data.shippingOrderId}
            </td>
            <td>
              {/* {productImage && (<img alt="" width="45" height="45" style={{ objectFit: 'contain' }} src={`//images.weserv.nl/?url=${productImage}`} />)} */}
              {productImage && (<img alt="" width="45" height="45" style={{ objectFit: 'contain' }} src={`${productImage}`} />)}
            </td>
            <td style={{ maxWidth: 280 }}>{`${data.jdProduct.name}${productVariant}` || '-'}</td>
            <td>{data.quantityproduct || 1}</td>
            <td>{setCurrency(data.settlement, 0)}</td>
            <td>{setCurrency(data.shippingFee, 0)}</td>
            <td style={{textAlign: 'center'}}>{setCurrency(data.payAmount, 0)}</td>
          </tr>
        )
      })
    } catch (error) {

    }
  }
  render() {
    const {
      loading,
      error,
      imei,
      title,
      children,
      datetimeaccess,
      order,
      productOrders,
      shippingOrders
    } = this.props;
    console.log(order)
    return (
      <div>
        {(loading && !error) && <div>Loading . . .</div>}
        {error && <h4 className="text-danger">{error}</h4>}
        {(!loading && !error) && (
          <div className="order-header-container d-flex" style={{ justifyContent: 'center' }} id="capture">
            <div style={{ maxWidth: 900, width: '100%' }}>
              <Table>
                <thead style={styles.flexCenter}>
                  <tr>
                    <td style={{ fontSize: 10, ...styles.borderNone, paddingTop: 0, paddingBottom: 0 }} colSpan="2">
                      {imei}{setDateFormat(datetimeaccess ? datetimeaccess : "")}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ ...styles.borderNone, paddingTop: 0, paddingBottom: 0 }} colSpan="2">
                      <div className="d-flex" style={{ justifyContent: 'center' }}>
                        <img
                          width="300" height="140"
                          src={iDeal}
                          // style={{ objectFit: 'contain' }}
                          alt=""
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={styles.borderNone} colSpan="3">
                      <div className="order-header-label text-center" style={{ fontSize: 36, marginBottom: 20, letterSpacing: 2 }}>{title}</div>
                    </td>
                  </tr>
                </thead>
                <tbody style={styles.flexCenter}>
                  {/* <tr>
                    <td className="order-header-label mb-2" style={styles.borderNone} colSpan="2">
                      #{order.orderCode}
                    </td>
                  </tr> */}
                  {/* desc */}
                  <tr>
                    <td style={{ ...styles.borderNone, width: '55%' }}>
                      <div style={{fontSize: 18}}>#{order.orderCode}</div>
                      <div className="order-detail-hint">ORDER DATE</div>
                      <div className="mb-2">{setDateFormat(order.createdDate)}</div>

                      {/* <div className="order-detail-hint">BILL TO USER</div>
                      <div className="mb-2">{order.user.name}</div>

                      <div className="order-detail-hint">PHONE NUMBER</div>
                      <div className="mb-2">{order.user.phoneNumber}</div> */}
                    </td>

                    <td style={{ ...styles.borderNone, width: '45%' }}>
                      {order && order.orderType === 'jd' && (
                        <React.Fragment>
                          <div className="order-detail-hint">SHIP TO</div>
                          <div className="mb-2">
                            {(order && order.shipmentAddressDetail) && (
                              <address>
                                <span className="text-success">{order.shipmentAddressDetail.alias || ''}<br /></span>
                                {order.shipmentAddressDetail.receiverName || ''} <br />
                                {order.shipmentAddressDetail.phoneNumber || ''}<br />
                                {order.shipmentAddressDetail.streetName || ''}<br />
                                {order.shipmentAddressDetail.district || ''}, {order.shipmentAddressDetail.city || ''}<br />
                                {order.shipmentAddressDetail.province || ''}{order.shipmentAddressDetail.zipCode ? `, ${order.shipmentAddressDetail.zipCode}` : ''}<br />
                              </address>
                            )}
                          </div>
                        </React.Fragment>
                      )}
                    </td>
                  </tr>

                  {order.struk && (
                    <tr>
                      <td colSpan="2" style={styles.borderNone}>{order ? order.struk : ''}</td>
                    </tr>
                  )}

                  {/* sub total */}
                  <tr className="mt-5 mb-5">
                    <td style={styles.borderNone} colSpan="2">
                      <Table>
                        <thead>
                          <tr style={styles.font}>                            
                            <th>Shipment ID</th>
                            <th>&nbsp;</th>
                            <th>Product Name</th>
                            <th>Qty</th>
                            <th>Subtotal</th>
                            <th>Shipping Fee</th>
                            <th className="text-center">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.getOrderList((productOrders && productOrders.length > 0) ? productOrders : (shippingOrders && shippingOrders.length > 0) ? shippingOrders : [])}
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td colSpan="2">Subtotal</td>
                            <td className="text-center">{setCurrency(order.subtotal, this.state.region)}</td>
                          </tr>

                          {
                            order.shippingFee &&
                            <tr>
                              <td style={styles.borderNone}></td>
                              <td style={styles.borderNone}></td>
                              <td style={styles.borderNone}></td>
                              <td style={styles.borderNone}></td>
                              <td colSpan="2">Shipping</td>
                              <td className="text-center">
                                {setCurrency(order.shippingFee, 0)}
                              </td>
                            </tr>
                          }

                          <tr>
                            <td style={styles.borderNone}></td>
                            <td style={styles.borderNone}></td>
                            <td style={styles.borderNone}></td>
                            <td style={styles.borderNone}></td>
                            <td colSpan="2"><b>Grand Total</b></td>
                            <td className="text-center"><b>{order ? setCurrency(order.total, this.state.region) : 0}</b></td>
                          </tr>
                        </tbody>
                      </Table>
                    </td>
                  </tr>

                  <tr className="mt-5">
                    <td style={styles.borderNone} colSpan="2">
                      {children}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        )}
      </div>
    );
  }
}
