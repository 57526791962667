import * as Yup from 'yup';
import _set from 'lodash/set';

export function validationErrorToObject(error: Yup.ValidationError) {
  let errorObject = {};
  error.inner.forEach(({ path, message }) => {
    _set(errorObject, path, message);
  });
  return errorObject;
}

export default async function errorCaster(error: Error) {
  let mError = {};

  if (error instanceof Yup.ValidationError) {
    mError = validationErrorToObject(error);
  }

  return mError;
}