import axios from './axios';
import { FormInterface } from '../components/ResetPasswordComponent';
import { DefaultError } from './ApiError';

export const login = async (merchantId: string, password: string) => {
  try {
    let response = await axios.post('/merchants/login', {
      merchantId,
      password,
    });

    return { data: response.data };
  } catch (e) {
    return { error: e.response ? e.response.data : DefaultError };
  }
};

export const getUserData = async () => {
  try {
    let response = await axios.get('/get-me');

    return { data: response.data };
  } catch (e) {
    return { error: e.response ? e.response.data : DefaultError }
  }
}

export const resetPassword = async (resetPasswordObject: FormInterface) => {
  try {
    const { oldPassword, newPassword, confirmPassword } = resetPasswordObject;
    let response = await axios.post('/change-password', {
      oldPassword,
      newPassword,
      newConfirmPassword: confirmPassword
    })

    return { data: response.data }
  } catch (e) {
    return { error: e.response ? e.response.data : DefaultError }
  }
}