import React from 'react';

interface Props {
  identifier: string,
  ErrorObject: { [key: string]: string },
}

const FormErrorLabel = (props: Props) => {
  const { ErrorObject, identifier } = props;

  if (ErrorObject && ErrorObject[identifier]) {
    return <p className="text-danger">{ErrorObject[identifier]}</p>;
  }

  return <React.Fragment />
}

export default FormErrorLabel
