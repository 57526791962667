import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoadingCircular = () => {
  return (
    <div className="loading-container">
      <CircularProgress className="loading-circular" />
    </div>
  );
};

export default LoadingCircular;
