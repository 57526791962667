import { toast } from 'react-toastify';

toast.configure({
  autoClose: 3000,
  draggable: false,
  containerId: 'Alert',
  hideProgressBar: true,
  pauseOnHover: false,
  enableMultiContainer: true,
})

const ToastWithType: {
  success: (message: string) => void;
  info: (message: string) => void;
  error: (message: string) => void;
  warn: (message: string) => void;
} = {
  success: (message: string) => {
    toast.success(message);
  },
  info: (message: string) => {
    toast.info(message);
  },
  error: (message: string) => {
    toast.error(message);
  },
  warn: (message: string) => {
    toast.warn(message);
  }
}

export default ToastWithType;