import React from 'react';
import Login from 'pages/Login';
import { DefaultLayout } from './containers';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authSelector } from 'models/auth';
import PurchaseOrder from 'views/Pages/PurchaseOrder';
// const PurchaseOrder = React.lazy(() => import("./views/Pages/PurchaseOrder"));

export default function RouteComponent() {
  const token = useSelector(authSelector.tokenSelector);
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/purchaseorder/:id/:download" component={PurchaseOrder}/>
        {localStorage.getItem('idealMerchantId') ? (
          <>
            <Route path="/" name="Home" component={DefaultLayout} />
          </>
        ) : (
          <>
            <Route exact path="/login" name="Login Page" component={Login} />
            <Redirect from="/" to="/login" />
          </>
        )}
      </Switch>
    </BrowserRouter>
  );
}