import { init, RematchRootState } from '@rematch/core';
import loadingPlugin from '@rematch/loading';
import createPersistPlugin from '@rematch/persist';
import auth from './auth';
import localforage from 'localforage'
import reduxPersistTransformFilter from '../helpers/reduxPersistTransformFilter';

const models = {
  auth,
}

const persistPlugin = createPersistPlugin({
  key: 'root',
  whitelist: ['auth'],
  throttle: 2000,
  version: 1,
  storage: localforage,
  transforms: [
    reduxPersistTransformFilter([
      { key: 'auth', whitelists: ['token', 'data'] },
    ]),
  ],
});

export const store = init({
  models,
  plugins: [persistPlugin, loadingPlugin({})],
});

export type RematchDispatch = typeof store.dispatch;
export type RootState = RematchRootState<typeof models>;