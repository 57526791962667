import axios from "../helpers/Axios";
import config from "../config";

export async function loginMerchant (merchantId, password ) {
    try {
      let response = await axios.Post({
        url: `${config.basev2URL}/login`,
        data: { merchantId, password }
      });

      console.log(response);
      if (response.statusCode !== 200) {
        console.log(response);
        return {  error: response.message };
      }
  
      return { data: response.data };
    } catch (e) {
      return { error: e.response.data };
    }
};
  
export async function getUserData() {
    try {
      let response = await axios.Get({
        url: `${config.basev2URL}/get-me`
      });

      if (response.statusCode !== 200) {
        return {  error: response.message };
      }
  
      return { data: response.data };
    } catch (e) {
      return { error: e.response.data };
    }
}
  
export async function resetPassword ({ oldPassword, newPassword, confirmPassword }) {
    try {
      let response = await axios.Post({
        url : `${config.basev2URL}/change-password`,
        data: {
            oldPassword,
            newPassword,
            newConfirmPassword: confirmPassword
        }
      })
      
      if (response.statusCode !== 200) {
        return {  error: response.message };
      }

      return { data: response.data }
    } catch (e) {
      return { error: e.response.data };
    }
}