import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector, authDispatcher } from 'models/auth';
import { setToken, setUnAuthorized } from './repositories/axios';
import { setLocale } from 'yup';
import yupValidation from './locales/validation.yup';

setLocale(yupValidation as any);

export default function Handler() {
  const dispatch = useDispatch();
  const { logout, getMe } = authDispatcher(dispatch);
  const token = useSelector(authSelector.tokenSelector);
  const isAuthenticated = useSelector(authSelector.isAuthenticatedSelector);

  useEffect(() => {
    setUnAuthorized(logout);
  }, [setUnAuthorized, logout]);

  useEffect(() => {
    if (token) {
      setToken(token);
    }
  }, [setToken, token]);

  useEffect(() => {
    if (isAuthenticated) {
      getMe();
    }
  }, [isAuthenticated, getMe])

  return null;
}
