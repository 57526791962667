import React from 'react';
import { Provider } from 'react-redux';
import { store } from './models';
import { getPersistor } from '@rematch/persist';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer, toast } from 'react-toastify';
import Route from './route';
import Handler from './handler';
import ResetPasswordModal from "./components/Modal/ResetPasswordModal";

// CSS
import 'font-awesome/css/font-awesome.min.css';
import '@coreui/icons/css/free.min.css';
import 'simple-line-icons/css/simple-line-icons.css';
import 'react-toastify/dist/ReactToastify.css';

import './css/style.css';
import './css/custom-style.css';
// CSS

// Persistor
const persistor = getPersistor();

let toastConfig = {
  position: toast.POSITION.TOP_CENTER,
  pauseOnHover: false,
  hideProgressBar: true,
  enableMultiContainer: false,
  containerId: 'Alert',
}

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <ToastContainer {...toastConfig} />
        <Handler />
        <ResetPasswordModal />
        <Route />
      </PersistGate>
    </Provider>
  );
}
