import React, { useState, useCallback } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  Label,
} from 'reactstrap';

import { resetPassword } from '../repositories/auth';

// Interface
import { ApiError } from '../repositories/ApiError';

// Validation
import { ResetPasswordSchema } from './yup';
import errorCaster from '../helpers/errorCaster';
import FormErrorLabel from './FormErrorLabel';

interface Props {
  onSuccess?: () => void;
  onFailed?: (e: ApiError) => void;
  onLoadingChange?: (state: boolean) => void;
  onDisableButtonHandle?: (state: boolean) => void;
  onDisableButton: boolean;
}

interface ShowPasswordInterface {
  oldPassword: passwordType;
  newPassword: passwordType;
  confirmPassword: passwordType;
}

export interface FormInterface {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

type passwordType = 'text' | 'password';
type showPasswordType = 'oldPassword' | 'newPassword' | 'confirmPassword';

let initialState = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const ResetPasswordComponent = (props: Props) => {
  const {
    onSuccess,
    onFailed,
    onLoadingChange,
    onDisableButton,
    onDisableButtonHandle,
  } = props;
  const [formError, setFormError] = useState<{}>({});
  const [formData, setFormData] = useState<FormInterface>(initialState);
  const [showPassword, setShowPassword] = useState<ShowPasswordInterface>({
    oldPassword: 'password',
    newPassword: 'password',
    confirmPassword: 'password',
  });

  const handleChange = (dataObject: { [key: string]: string }) => {
    setFormData(prev => ({
      ...prev,
      ...dataObject,
    }));
  };

  const handleShowPassword = useCallback(
    (key: showPasswordType) => {
      setShowPassword((prev: ShowPasswordInterface) => ({
        ...prev,
        [key]: prev[key] == 'password' ? 'text' : 'password',
      }));
    },
    [setShowPassword],
  );

  const onSubmit = async () => {
    try {
      onDisableButtonHandle && onDisableButtonHandle(true);
      onLoadingChange && onLoadingChange(true);
      await ResetPasswordSchema.validate(formData, {
        abortEarly: false,
      });

      let { data, error } = await resetPassword(formData);

      if (data) {
        onSuccess && onSuccess();
      } else {
        onFailed && onFailed(error as any);
      }
      onLoadingChange && onLoadingChange(false);
      onDisableButtonHandle && onDisableButtonHandle(false);
    } catch (e) {
      let validateError = await errorCaster(e);
      setFormError(validateError);
      onLoadingChange && onLoadingChange(false);
      onDisableButtonHandle && onDisableButtonHandle(false);
    }
  };

  return (
    <Row className="animated fadeIn">
      <Col md="12" className="mb-4">
        <CardGroup>
          <Card className="p-4">
            <CardBody className="p-0">
              <Form encType="multipart/form-data" className="style-label">
                <FormGroup row>
                  <Col xs="12" md="12">
                    <Label>Old Password</Label>
                    <div
                      className="toggle-change-input"
                      onClick={() => handleShowPassword('oldPassword')}
                    >
                      {showPassword.oldPassword == 'text' ? (
                        <i className="fa fa-eye" />
                      ) : (
                        <i className="fa fa-eye-slash" />
                      )}
                    </div>
                    <Input
                      type={showPassword.oldPassword}
                      className="p-r-30"
                      name="oldPassword"
                      value={formData.oldPassword}
                      placeholder="Input Old Password"
                      autoComplete="value"
                      onChange={e =>
                        handleChange({ oldPassword: e.target.value })
                      }
                    />
                    <FormErrorLabel
                      ErrorObject={formError}
                      identifier="oldPassword"
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs="12" md="12">
                    <Label>New Password</Label>
                    <div
                      className="toggle-change-input"
                      onClick={() => handleShowPassword('newPassword')}
                    >
                      {showPassword.newPassword == 'text' ? (
                        <i className="fa fa-eye" />
                      ) : (
                        <i className="fa fa-eye-slash" />
                      )}
                    </div>
                    <Input
                      type={showPassword.newPassword}
                      className="p-r-30"
                      name="newPassword"
                      value={formData.newPassword}
                      placeholder="Input New Password"
                      autoComplete="value"
                      onChange={e =>
                        handleChange({ newPassword: e.target.value })
                      }
                    />
                    <FormErrorLabel
                      ErrorObject={formError}
                      identifier="newPassword"
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs="12" md="12">
                    <Label>Confirm Password</Label>
                    <div
                      className="toggle-change-input"
                      onClick={() => handleShowPassword('confirmPassword')}
                    >
                      {showPassword.confirmPassword == 'text' ? (
                        <i className="fa fa-eye" />
                      ) : (
                        <i className="fa fa-eye-slash" />
                      )}
                    </div>
                    <Input
                      type={showPassword.confirmPassword}
                      className="p-r-30"
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      placeholder="Input Confirm Password"
                      autoComplete="value"
                      onChange={e =>
                        handleChange({ confirmPassword: e.target.value })
                      }
                    />
                    <FormErrorLabel
                      ErrorObject={formError}
                      identifier="confirmPassword"
                    />
                  </Col>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </CardGroup>
        <div className="row mt-4">
          <div className="modal-button">
            <Button
              className="btn-primary full-width"
              onClick={onSubmit}
              disabled={onDisableButton}
            >
              Reset
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ResetPasswordComponent;
