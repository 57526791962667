import React, { useState, useCallback, useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { loginMerchant } from '../services/authService';
import LoadingCircular from '../components/Loading/LoadingCircular';
import logo from '../assets/img/brand/logo-ideal-horizontal.png';

interface Props {
  isAuthenticated: boolean;
  setLogin: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Login(props: Props) {

  const [form, setForm] = useState({ merchantId: '', password: '' });
  const [loading, setLoading] = useState(false);
  const { merchantId, password } = form;

  const onChange = useCallback(
    e => {
      let name = e.target.name;
      let value = e.target.value;

      setForm(prevForm => ({
        ...prevForm,
        [name]: value,
      }));
    },
    [setForm],
  );

  const login = async () => {
    setLoading(true)
    let { data, error } = await loginMerchant(merchantId, password);
    if (error) { toast.error(error.message ? error.message : error.statusCode, {autoClose: 2000}); setLoading(false); return }
    var values = new Array();
    var oneday = new Date();
    oneday.setHours(oneday.getHours() + 24); //one day from now
    values.push("hello world");
    values.push(oneday);
    console.log(data);
    localStorage.setItem('0', values.join(";"));
    localStorage.setItem('idealMerchantinfo', JSON.stringify(data));
    localStorage.setItem('idealMerchantDBId', data._id);
    localStorage.setItem('idealMerchantId', data.merchantId);
    localStorage.setItem('merchantAsPartner', data.isPartner);
    localStorage.setItem('merchantAllowCreateOrder', data.allowCreateOrder);
    if (data.adminInfo) { localStorage.setItem('idealAdmin', JSON.stringify(data.adminInfo)); }
    window.location.reload();
  }

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      login();
    }
  };

  return (
    <div className="app flex-row align-items-center">
      <div style={{ margin: '0 auto', maxWidth: 400, minWidth: 400 }}>
        <div className="d-flex justify-content-center mb-5">
          <img src={logo} alt="iDeal Logo" width="250" style={{ objectFit: 'contain' }} />
        </div>
        <CardGroup style={{width: '100%'}}>
          <Card className="p-0">
            <CardBody className="p-3">
              <Form className="style-label">
                <h3 className="text-center mb-4">Merchant Login</h3>
                <Label className='mb-0' htmlFor="username" style={{fontSize: 10}}>User Id</Label>
                <Input
                  style={{fontSize: 12}}
                  className='mb-2'
                  onKeyPress={handleKeyPress}
                  type="text"
                  name="merchantId"
                  value={merchantId}
                  placeholder="Username"
                  autoComplete="username"
                  onChange={onChange}
                />
                <Label className='mb-0' htmlFor="username" style={{fontSize: 10}}>Password</Label>
                <Input
                  style={{fontSize: 12}}
                  className='mb-3'
                  onKeyPress={handleKeyPress}
                  type="password"
                  name="password"
                  value={password}
                  placeholder="Password"
                  autoComplete="current-password"
                  onChange={onChange}
                />
                <Row>
                  <Col xs="12" className="text-center">
                    {loading ? (
                      <LoadingCircular />
                    ) : (
                      <Button
                        color="primary"
                        onClick={() => login()}
                        className="px-4 btn-green"
                      >
                        Login
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </CardGroup>
      </div>
    </div>
  );
}


{/* <div className="app flex-row align-items-center">
        <div style={{ margin: '0 auto', maxWidth: 400, minWidth: 400 }}>
          <div className="d-flex justify-content-center mb-5">
            <img src={logo} alt="iDeal Logo" width="250" style={{ objectFit: 'contain' }} />
          </div>

          <CardComponent style={{ group: { width: '100%' } }}>
            <h3 className="text-center mb-4">Login</h3>
            <InputComponent
              className={{ group: 'mb-3' }}
              label="ADMIN ID"
              placeholder="Username"
              autoComplete="username"
              name="username"
              onKeyPress={this.handleKeyPress}
              onChange={(e) => this.handleChange({ username: e.target.value })}
              value={this.state.username}
              disabled={authLoading}
            />
            <InputComponent
              label="PASSWORD"
              placeholder="Password"
              type="password"
              name="password"
              onKeyPress={this.handleKeyPress}
              onChange={(e) => this.handleChange({ password: e.target.value })}
              value={this.state.password}
              disabled={authLoading}
            />
            <div className="text-center mt-4">
              {!authLoading && (
                <Button
                  className="btn-green"
                  disabled={!(this.state.username.length > 0 && this.state.password.length > 0)}
                  onClick={this.login}
                >
                  Login
                </Button>
              )}
              <LoadingComponent show={authLoading} position="center" />
            </div>
          </CardComponent>

        </div>
      </div> */}