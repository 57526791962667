import axios from 'axios';
import config from '../config';

axios.defaults.baseURL = config.baseURL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

export const setUnAuthorized = (action: () => void) => {
  axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.response.status == '401') {
      action();
    }
    return Promise.reject(error);
  });
}

export const setToken = (token: string): void => {
  axios.defaults.headers.common['x-access-token'] = `Bearer ${token}`;
};

export default axios;
